<template>
  <v-tooltip :color="$vuetify.theme.dark ? '#303030' : '#efefef'" :open-on-click="$vuetify.breakpoint.smAndDown" :bottom="!position || position === 'bottom'" :right="position === 'right'" :left="position === 'left'" :top="position === 'top'">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :small="small" icon :class="btnClasses">
        <v-icon :style="opacity ? `opacity: ${opacity} !important;` : hover ? `opacity: .25 !important;` : ''" :class="[hover ? 'hover' : '', iconClasses ? iconClasses : 'grey--text']" v-bind="attrs" v-on="on"
        >
          mdi-{{icon ? icon : 'information-slab-symbol'}}
        </v-icon>
      </v-btn>
    </template>
    <div style="max-width:275px; min-width:125px" :class="$vuetify.theme.dark ? '' : 'black--text'">
      <b>{{title}}</b>
      <br v-if="title">
      <span v-html="text" :class="title ? 'grey--text' : ''"></span>
    </div>
  </v-tooltip>
</template>

<script>
  export default {
    props: [
      'title', 'text', 'icon', 'iconClasses', 'position', 'opacity', 'btnClasses', 'hover',
      'x_small', 'small', 'medium', 'large', 'x_large'
    ],
  }
</script>

<style scoped>
  .hover:hover { opacity: 1 !important;}
</style>