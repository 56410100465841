<template>
  <v-btn-toggle
    rounded
    dense
    group
    class="grey"
    :class="[buttonClasses, $vuetify.theme.dark ? 'darken-3' : 'lighten-3']"
  >
    <v-btn small icon @click="addPropToFavorites(propId, selectedFavoritesList)" class="ma-0 pa-0">
      <v-icon small class="orange--text">
        <!-- :class="active ? 'orange--text' : 'grey--text'" -->
        {{ active === 'multiple' ? 'mdi-star-plus' : active ? 'mdi-star' : 'mdi-star-outline' }}
        <!-- mdi-star-plus -->
      </v-icon>
    </v-btn>

    <v-menu
      v-if="!specificFavoritesKey || specificFavoritesKey.length == 0" 
      v-model="menu"
      offset-y
      :close-on-content-click="false"
    >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="ma-0 pa-0 capitalize"
            :class="showListName && selectedFavoritesList != 'default' ? 'pr-1' : ''"
            :icon="!showListName || selectedFavoritesList == 'default'"
            small
            @click="updateFavoritesFromStorage();"
          >
            <span v-if="showListName && selectedFavoritesList != 'default'" class="px-1">
              {{ selectedFavoritesList }}
            </span>
            <v-icon small class="">
              {{ menu ? 'mdi-menu-close' : 'mdi-menu-open' }}
              <!-- {{ menu ? 'mdi-menu-up' : 'mdi-menu-down' }} -->
            </v-icon>
          </v-btn>
        </template>
        <v-list dense class="pa-0">
          <v-list-item-title class="py-1 px-3 ma-0 small primary--text">
            {{  $t('Favorites List') }}:
          </v-list-item-title>
          <v-divider></v-divider>
          <v-list-item
            v-for="(item, listName) in favorites"
            :key="listName"
            @click="setSelectedFavoritesList(listName); menu=false;"
            class="pa-0 px-3 ma-0"
            :class="selectedFavoritesList == listName || (listName == 'default' && selectedFavoritesList == '') ? 'primary' : ''"
          >
            <!-- :class="selectedFavoritesList == listName || (listName == 'default' && selectedFavoritesList == '') ? 'primary' : ''" -->
            <!-- @click="$i18n.locale = item" -->
              <!-- {{item}} -->
              {{listName == 'default' ? $t('Main list') : listName}}
              <v-icon v-if="favorites[listName].includes(propId)" small :title="$t('This prop is in the list') + ` '${listName}'.`" class="ml-1" color="orange">
                mdi-star-check
              </v-icon>
              <!-- <v-icon v-else small color="">
                mdi-checkbox-blank-outline
              </v-icon> -->
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="pa-0 px-3 ma-0">
            <v-text-field
              v-show="createNewListToggle"
              v-model="newListName"
              ref="createNewListInput"
              dense
              hide-details
              :placeholder="$t('Enter new name of list..')"
              append-icon="mdi-content-save"
              @keyup.enter="createNewListToggle = !createNewListToggle; saveNewList(); menu=false"
              @click:append="createNewListToggle = !createNewListToggle; saveNewList(); menu=false"
            ></v-text-field>
            <v-btn
              v-show="!createNewListToggle"
              @click="createNewListToggle = !createNewListToggle; $nextTick(() => { $refs['createNewListInput'].focus() });"
              dense
              plain
              small
              color="primary"
              class="px-0"
            >
              {{  $t('New list..') }}
              <v-icon x-small>
                mdi-plus-thick
              </v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
    </v-menu>
  </v-btn-toggle>
</template>

<script>
  export default {
    name: 'Favorites',
    props: {
      auth: Boolean,
      user: Object,
      settings: Object,
      propId: Number,
      specificFavoritesKey: String,
      buttonClasses: String,
      showListName: Boolean,
    },
    data() {
    return {
      menu: false,
      createNewListToggle: false,
      newListName: '',
      selectedFavoritesList: 'default',
      favorites: {'default': []}
    }
  },
  created() {
    this.updateFavoritesFromStorage();
  },

  computed: {
    active() {
      const matches = Object.values(this.favorites).filter(array => array.includes(this.propId)).length;
      if (matches > 1) return "multiple";
      if (matches === 1) return true;
      return false;
    }
  },

  watch: {
    favorites: {
      async handler(newFavorites) {
        // Dont save empty to localStorage as this prompts the cookie-question
        if(JSON.stringify(newFavorites) !== '{"default":[]}') {
          // Ensure localStorage syncs with changes
          if(!await this.$helpers.writeLocalStorage('favorites', newFavorites)) {
            // console.log("no cookies for you, remove from storage")
            this.favorites = {"default":[]};
          }
        } else {
          // if available, remove favorites in case last prop in default list was removed
          this.$helpers.clearLocalStorageItem('favorites');
        }
      },
      deep: true
    },
  },

  methods: {
    updateFavoritesFromStorage() {
      this.selectedFavoritesList = this.$helpers.readLocalStorage('selectedFavoritesList') || 'default',
      this.favorites = this.$helpers.readLocalStorage('favorites') || {'default': []}
    },

    setSelectedFavoritesList(listName) {
      this.updateFavoritesFromStorage();
      this.selectedFavoritesList = listName;
      this.$helpers.writeLocalStorage('selectedFavoritesList', listName);
      // console.log(this.selectedFavoritesList);
    },

    saveNewList() {
      this.updateFavoritesFromStorage();
      if(!this.newListName) return;  // Do not save empty list
      this.setSelectedFavoritesList(this.newListName);
      this.favorites = { [this.newListName]: [], ...this.favorites };
      this.$helpers.writeLocalStorage('favorites', this.favorites);
      this.$helpers.writeLocalStorage('selectedFavoritesList', this.newListName);
      this.addPropToFavorites(this.propId, this.newListName);
      this.newListName = '';
    },

    addPropToFavorites(propId, listName = "default") {
      // Get potentially updated favorites list from another instance of this component
      this.updateFavoritesFromStorage();
      if(this.specificFavoritesKey) {
        listName = this.specificFavoritesKey;
      } else {
        listName = this.selectedFavoritesList;
      }

      // Initialize favorites object if it doesn't exist
      if (!this.favorites) {
        this.favorites = {};
      }

      // Initialize specific list if it doesn't exist
      if (!this.favorites[listName]) {
        this.favorites[listName] = [];
      }

      // Toggle the propId in the specified list
      if (this.favorites[listName].includes(propId)) {
        this.favorites[listName] = this.favorites[listName].filter(fav => fav !== propId);
        this.$toasted.global.info({msg:`${this.$t('Removed prop from list')} "${listName}" ${this.active ? `- ${this.$t('Still in another list though')}` : ''}`});
      } else {
        this.favorites[listName].push(propId);
        this.$toasted.global.success({msg:`${this.$t('Added prop to')} "${listName}"`});
      }

      // Sync favorites with localStorage (trigger saving in localStorage by watcher)
      this.favorites = { ...this.favorites };

      this.$emit('updatedFavorites')  // Signal to Favorites.vue View to update the list
    }
  }
}
</script>

<style scoped>
  .hover:hover { opacity: 1 !important;}
</style>