<template>
  <div class="pb-16 mb-16">
    <div class="overline primary--text">Favorites</div>
    <v-row id="favorites">
      <v-col class="pl-2 pt-2" cols="12">
        <!-- PRINT ALL LISTS -->
        <v-btn icon v-if="$vuetify.breakpoint.smAndUp" small class="primary mr-2" @click="print('favorites')" :disabled="Object.keys(favorites).length == 0">
          <Info 
            :text="$t('Print favorites..')"
            icon="printer"
            opacity="1"
            iconClasses="white--text"
          ></Info>
        </v-btn>

        <!-- SHARE THESE LISTST AS URL -->
        <Share
          buttonColor="white"
          opacity="1"
          small
          buttonClasses="primary mr-2"
          :text="shareLink"
          :tooltip="shareLink.length > 2000 ? $t('Cannot share big lists like this.<br>Export favorites list to a file.') : $t('Share these lists')"
          :disabled="shareLink.length > 2000 || Object.keys(favorites).length == 0"
        ></Share>

        <!-- EXPORT -->
        <v-btn icon small class="primary mr-2" @click="downloadFavorites(favorites)" :disabled="Object.keys(favorites).length == 0">
          <Info 
            :text="$t('Export favorites list to a file..')"
            icon="content-save-upload"
            small
            opacity="1"
            iconClasses="white--text"
          ></Info>
        </v-btn>

        <!-- IMPORT -->
        <FileUpload
          class=""
          type=".dbcatourne"
          :multiple="false"
          :small="true"
          :returnContent="true"
          :tooltip="$t('Import favorites list..')"
          icon="content-save-download"
          iconClasses=""
          buttonClasses="primary"
          containerClasses="inlineBlock mr-2"
          @uploaded="importJson($event)"
          @error="$toasted.global.error({msg:$event})"
        ></FileUpload>
          <!-- 
          @uploadStarted="importJson($event)"
                      buttonClasses="pink darken-3 elevation-8"
          position="right"
          icon="server"
         -->

        <!-- CREATE NEW LIST -->
        <v-text-field
          v-show="createNewListToggle"
          v-model="newListName"
          ref="createNewListInput"
          class="inlineBlock ma-0"
          dense
          hide-details
          :placeholder="$t('Enter new name of list..')"
          append-icon="mdi-content-save"
          @blur="createNewListToggle = false; newListName = ''"
          @keyup.enter="createNewListToggle = !createNewListToggle; saveNewList(newListName);"
          @click:append="createNewListToggle = !createNewListToggle; saveNewList(newListName);"
        ></v-text-field>
        <v-btn
          v-show="!createNewListToggle"
          @click="createNewListToggle = !createNewListToggle; $nextTick(() => { $refs['createNewListInput'].focus() });"
          dense
          small
          rounded
          color="primary"
          class="px-2"
        >
          {{  $t('New list..') }}
          <v-icon x-small>
            mdi-plus-thick
          </v-icon>
        </v-btn>

       <!--  <br>
        importFavorites:
        <code class="block">{{ importFavorites }}</code>
        <br>
        tempListNames:
        <code class="block">{{ tempListNames }}</code>
        <br>
        favorites:
        <code class="block">{{ Object.keys(favorites) }}</code>
        <br>
        the same:
        <code class="block">{{ JSON.stringify(tempListNames) === JSON.stringify(Object.keys(favorites)) }}</code> -->
      </v-col>
    </v-row>
    <v-row v-if="Object.keys(favorites).length">
        <v-col class="pl-2 pt-2" cols="12" v-for="(items, listName, index) in favorites" :key="listName">
          <v-card>
            <v-card-title class="py-0">
              <!-- <span class="grey--text">List:</span> -->
              <!-- {{ items }}  / -->
              <!-- {{ listName }} -->
              <v-text-field
                v-model="tempListNames[index]"
                @change="updateListName(index)"
                :disabled="listName === 'default'"
                :rules="inputRules"
                :append-icon="isEditing === index ? 'mdi-content-save' : listName === 'default' ? '' : 'mdi-pencil'"
                @click:append="isEditing === index ? updateListName(index) : false"
                @focus="isEditing = index"
                @blur="isEditing = false"
                :append-outer-icon="listName === 'default' ? '' : 'mdi-delete'"
                @click:append-outer="favorites[listName].length ? askDeleteList(listName) : deleteList(listName)"
                type="text"
                :prefix="`List ${Object.keys(favorites).length-index}:`"
                hint="Edit this name and enter"
                dense
              ></v-text-field>
              <!-- append-outer-icon="mdi-trash-can" -->
            </v-card-title>
            <v-card-text class="pb-0">
              <ul class="noList pa-0">
                  <!-- Empty slot to allow dragging into an empty list -->
                <li
                  v-if="!items.length || $vuetify.breakpoint.smAndUp"
                  @dragover.prevent
                  @drop="drop(null, listName, 0)"
                >
                  <span :class="items.length ? 'transparent' : 'grey--text'">
                    {{ $t('No props in this list yet.')}}
                    {{ $vuetify.breakpoint.smAndUp ? $t('Drag something here!') : '' }}
                  </span>
                </li>
                <!-- eslint-disable-next-line vue/no-unused-vars -->
                <li v-for="(item, indexProps) in items"
                  :key="'fav_list-'+listName+'-'+indexProps+''+item"
                  draggable="true"
                  @dragstart="dragStart(item, listName)"
                  @dragover.prevent
                  @drop="drop(item, listName, indexProps)"
                  @dragend="dragEnd"
                >
                  <!-- class="hoverList" -->
                  <v-icon
                    v-if="$vuetify.breakpoint.smAndUp"
                    class="mb-1 grey--text noPrint"
                    style="cursor: grab; margin-top:48px;"
                  >
                    mdi-drag
                  </v-icon>
                  <!-- {{ indexProps+1 }}. Prop: -->
                  <!-- {{ listName }} -->
                  <Proplist
                    :style="$vuetify.breakpoint.smAndUp ? `width: calc(100% - 2em)` : ''"
                    :user="user"
                    :listView="true"
                    :showTags="$vuetify.breakpoint.smAndUp"
                    :propIndex="item"
                    :propId="item"
                    :cardSize="120"
                    :categories="[]"
                    :specificFavoritesKey="listName"
                    @updatedFavorites="getFavoritesFromLocalStorage()"
                  ></Proplist>
                  <hr v-if="indexProps !== items.length-1" class="mb-4 op-12">
                </li>
                <li
                  @dragover.prevent
                  @drop="drop(null, listName, items.length+1)"
                >
                  <span class="transparent">End slot</span>
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
    </v-row>

    <v-row v-else>
      <v-col class="pl-2 pt-2 grey--text" cols="12">
        {{ $t('No favorites yet!') }}
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col class="pl-2 pt-2" cols="12">
        
      </v-col>
    </v-row> -->

    <!-- <code class="block">{{ favorites }}</code> -->
    <!-- IMPORT LISTS DIALOG -->
    <Dialog
      :trigger="importFavoritesDialog"
      icon="set-split"
      :title="$t('Merge, add or replace favorites?')"
      :text="`${$t(`You are importing`)} ${importFavorites.flattenedArray ? importFavorites.flattenedArray.length : 0} ${$t(`favorites`)}.<br>${$t(`What do you want to do with your current favorites?`)} <ul class='niceList my-2'><li><span class='orange--text uppercase'>${$t(`Merge`)}</span> ${$t(`your current and the new lists together`)}</li><li><span class='green--text uppercase'>${$t(`Add`)}</span> ${$t(`imported ones to one single list`)}: '${importFavorites.newListName}'</li><li><span class='red--text uppercase'>${$t(`Replace`)}</span> ${$t(`your favorites`)}</li></ul>`"
      :width="600"
      :persistent="false"
      :buttons="[
        {name: $t('Cancel'), color: '', icon: 'close'}, {name: 'spacer'},
        {name: $t('Merge'), color: 'orange', icon: 'set-split'},
        {name: $t('Add new list'), color: 'green', icon: 'playlist-plus'},
        {name: $t('Replace'), color: 'red', icon: 'swap-horizontal-bold-variant'}
      ]"
      @dialog="importFavoritesDialog = $event, abortImport($event)"
      @clickBtn2="mergeList(importFavorites.mergedFavorites); importFavorites = {};"
      @clickBtn3="saveNewList(importFavorites.newListName, importFavorites.flattenedArray); importFavorites = {};"
      @clickBtn4="importJson(importFavorites.fileContents, true)"
    ></Dialog>

    <!-- DELETE LIST DIALOG -->
    <Dialog
      :trigger="deleteListDialog"
      icon="delete"
      :title="`${$t('Delete')} '${listToDelete}' ${$t('forever')}?`"
      :text="`${$t('Do you wish to delete this list and its')} ${$helpers.plural(favorites[listToDelete] ? favorites[listToDelete].length : 0, [$t('prop'), $t('props')])} ${$t('forever')}?<br>${$t('You can also merge this list into the default one.')}`"
      :width="600"
      titleClasses="red white--text"
      :persistent="false"
      :buttons="[{name: $t('Cancel'), color:'', icon: 'close'}, {name: 'spacer'}, {name: $t('Move items to default'), color:'green', icon: 'set-split'}, {name: $t('Delete list'), color:'red', icon: 'delete'}]"
      @dialog="deleteListDialog = $event"
      @clickBtn2="moveToDefaultList(listToDelete, 'default')"
      @clickBtn3="deleteList(listToDelete)"
    ></Dialog>
  </div>
</template>

<script>
import axios from 'axios';
import Proplist from "../components/Proplist.vue";
import Share from '@/components/Share'
import Dialog from '@/components/Dialog'
import Info from '@/components/Info'
import FileUpload from '@/components/FileUpload'

export default {
  name: 'Favorites',
  props: {
    auth: Boolean,
    user: Object,
    settings: Object
  },
  components: {
    Proplist,
    Share,
    Info,
    Dialog,
    FileUpload,
  },
  data() {
    return {
      on: false,
      attrs: false,
      favorites: {},
      tempListNames: [],
      createNewListToggle: false,
      newListName: '',
      deleteListDialog: false,
      isEditing: false,
      importFavoritesDialog: false,
      importFavorites: {},
      listToDelete: -1,
    }
  },
  created() {
    this.getFavoritesFromLocalStorage();

    // Import favorites by URL
    if(this.$route.query.favorites) {
      let favsFromUrl = JSON.parse(decodeURIComponent(this.$route.query.favorites));
      
      // No favorites yet - import as is
      if(!Object.keys(this.favorites).length) {
        this.$toasted.global.info({msg:`${Object.keys(favsFromUrl).length} ${this.$t('favorite lists imported.')}`});
        this.favorites = favsFromUrl;
        this.$helpers.writeLocalStorage('favorites', this.favorites);
        this.$helpers.clearLocalStorageItem('selectedFavoritesList');
        this.tempListNames = Object.keys(this.favorites);
      } else {
        // ASK IF JOINING FAVS
        let flattenedArray = Object.values(favsFromUrl).flat();
        const date = new Date();
        const yy = String(date.getFullYear()).slice(-2);
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        let newListName = `Import ${yy}${mm}${dd} (${Object.keys(this.favorites).length + 1})`;
        let mergedFavorites = this.mergeObjects(this.favorites, favsFromUrl)

        this.importFavorites = {
          newListName: newListName,
          flattenedArray: flattenedArray,
          mergedFavorites: mergedFavorites,
          fileContents: favsFromUrl
        }
        this.$nextTick(() => {
          this.importFavoritesDialog = true;
        });
      }
      const updatedQuery = { ...this.$route.query };
      delete updatedQuery.favorites;  // Remove the 'favorites' parameter if it exists
      this.$router.replace({ query: updatedQuery });
    }

  },
  watch: {
  },

  computed: {
    inputRules() {
      // Return an array of rules (one or more)
      return [
        v => !!v || this.$t('Input cannot be empty')
      ];
    },
    shareLink() {
      return `${this.mainDomain()}/#/favorites?favorites=${encodeURIComponent(JSON.stringify(this.favorites))}`;
    }
  },

  methods: {
    getFavoritesFromLocalStorage() {
      this.$nextTick(() => {
        this.favorites = this.$helpers.readLocalStorage('favorites');
        console.log("getFavoritesFromLocalStorage..")
        if(this.favorites) {
          this.tempListNames = Object.keys(this.favorites);
        } else {
          this.favorites = {};
          this.tempListNames = [];
        }
      });
    },
    // Start dragging: store the dragged item and its original list
    dragStart(item, listName) {
      this.draggedItem = item;
      this.draggedFrom = listName;
    },

    // eslint-disable-next-line no-unused-vars
    drop(item, listName, index) {
      // Remove the item from the original list
      const fromIndex = this.favorites[this.draggedFrom].indexOf(this.draggedItem);
      if (fromIndex !== -1) {
        this.favorites[this.draggedFrom].splice(fromIndex, 1);
      }

      // Insert the item at the target index
      if (index === 0) {
        this.favorites[listName].unshift(this.draggedItem); // Insert at first position
      } else if (index >= this.favorites[listName].length) {
        this.favorites[listName].push(this.draggedItem); // Insert at last position
      } else {
        this.favorites[listName].splice(index, 0, this.draggedItem); // Insert at specific position
      }

      // Update localStorage
      this.$helpers.writeLocalStorage('favorites', this.favorites);

      // Reset dragged item data
      this.draggedItem = null;
      this.draggedFrom = null;
    },

    // Reset after drag ends
    dragEnd() {
      this.draggedItem = null;
      this.draggedFrom = null;
    },

    mergeObjects(obj1, obj2) {
      const result = { ...obj1 };
      Object.keys(obj2).forEach(key => {
        if (Array.isArray(obj2[key]) && Array.isArray(result[key])) {
          // Combine arrays and remove duplicates
          result[key] = Array.from(new Set([...result[key], ...obj2[key]]));
        } else {
          // Otherwise, just set the value from obj2
          result[key] = obj2[key];
        }
      });
      return result;
    },

    mergeList(favorites) {
      this.favorites = favorites;
      this.$helpers.writeLocalStorage('favorites', this.favorites);
      this.tempListNames = Object.keys(this.favorites);
      this.$toasted.global.success({msg:`${this.$t('Merged favorites into your existing ones')}.`});
    },

    saveNewList(listName, data=false) {
      if(!listName) return;  // Do not save empty list
      this.favorites = { [listName]: data || [], ...this.favorites };
      this.$helpers.writeLocalStorage('favorites', this.favorites);
      this.$helpers.writeLocalStorage('selectedFavoritesList', listName);
      this.tempListNames = Object.keys(this.favorites);
      this.newListName = '';
      this.$toasted.global.success({msg:`${this.$t('Added new list')} '${listName}'.`});
    },

    askDeleteList(deleteList) {
      this.listToDelete = deleteList;
      this.deleteListDialog = true;
    },
    
    deleteList(deleteList) {
      console.log("List to be deleted", deleteList);
      delete this.favorites[deleteList];
      this.$helpers.writeLocalStorage('favorites', this.favorites);
      if(deleteList === this.$helpers.readLocalStorage('selectedFavoritesList')) {
        this.$helpers.writeLocalStorage('selectedFavoritesList', 'default');
      }
      this.tempListNames = Object.keys(this.favorites);
      this.$toasted.global.info({msg:`${this.$t('List')} '${deleteList}' ${this.$t('deleted')}.`});
    },

    moveToDefaultList(source, target) {
      console.log("merge the list: ", source, "  into ", target);
      this.favorites[target] = [...this.favorites[target], ...this.favorites[source]];
      delete this.favorites[source];
      this.$helpers.writeLocalStorage('favorites', this.favorites);
      if(source === this.$helpers.readLocalStorage('selectedFavoritesList')) {
        this.$helpers.writeLocalStorage('selectedFavoritesList', 'default');
      }
      this.tempListNames = Object.keys(this.favorites);
      this.$toasted.global.info({msg:`${this.$t('List')} '${source}' ${this.$t('moved to')} '${target}' ${this.$t('and')} ${this.$t('deleted')}.`});
    },

    updateListName(index) {
      if(this.tempListNames[index] === Object.keys(this.favorites)[index]) {
        console.log("old as new")
        return
      }
      if(this.tempListNames[index].length == 0) {
        this.tempListNames[index] = this.$t("Unnamed list")+" "+(index+1)
      }
      // Update the key in the favorites object
      let oldListName = Object.keys(this.favorites)[index];
      // Assign the value to the new key and delete the old key
      this.favorites = this.insertKeyInOrder(this.favorites, this.tempListNames[index], this.favorites[oldListName], index);
      this.$delete(this.favorites, oldListName);
      this.$helpers.writeLocalStorage('favorites', this.favorites);

      // Update selected lsit if its the same as i'm editing
      if(oldListName === this.$helpers.readLocalStorage('selectedFavoritesList')) {
        this.$helpers.writeLocalStorage('selectedFavoritesList', this.tempListNames[index]);
      }
      this.tempListNames = Object.keys(this.favorites);
      this.isEditing = false;
    },

    insertKeyInOrder(obj, key, value, index) {
      // Convert the object to an array of [key, value] pairs
      const entries = Object.entries(obj);

      // Insert the new [key, value] pair at the desired index
      entries.splice(index, 0, [key, value]);

      // Convert back to an object to maintain the updated order
      return Object.fromEntries(entries);
    },

    abortImport(event) {
      if(!event) {
        this.importFavorites = {};
      }
    },

    importJson(fileContents, replace=false) {
      if(!fileContents || !Object.keys(fileContents).length) {
        this.$toasted.global.info({msg:`${this.$t('This file contains no usable data.')}`});
        return;
      }

      let flattenedArray = Object.values(fileContents).flat();

      // Replace current favs if no current favs, or forced to do so with replace=true
      if(replace || !Object.values(this.favorites).flat().length) {
        this.favorites = fileContents;
        this.importFavorites = {};
        this.$toasted.global.info({msg:`${this.$t('Imported')} ${flattenedArray.length} ${this.$t('favorites')}.`});

        this.$helpers.writeLocalStorage('favorites', this.favorites);
        this.$helpers.clearLocalStorageItem('selectedFavoritesList');
        this.tempListNames = Object.keys(this.favorites);
        return;
      }

      const date = new Date();
      const yy = String(date.getFullYear()).slice(-2);
      const mm = String(date.getMonth() + 1).padStart(2, '0');
      const dd = String(date.getDate()).padStart(2, '0');
      let newListName = `Import ${yy}${mm}${dd} (${Object.keys(this.favorites).length + 1})`;
      let mergedFavorites = this.mergeObjects(this.favorites, fileContents)

      this.importFavorites = {
        newListName: newListName,
        flattenedArray: flattenedArray,
        mergedFavorites: mergedFavorites,
        fileContents: fileContents
      }
      this.importFavoritesDialog = true;
    },

    async print(id) {
      let table;
      if(id === 'favorites') {
        table = document.createElement("table");
        let counter = 0;

        // Iterate over each key in favorites
        for (const key of Object.keys(this.favorites)) {
          // Add a row for the key title
          const titleRow = document.createElement("tr");
          const titleCell = document.createElement("td");
          titleCell.colSpan = 2;
          if(counter > 0) titleCell.style.pageBreakBefore = 'always';
          titleCell.classList.add("title");
          titleCell.classList.add("capitalize");
          titleCell.innerHTML = `<strong>${key}</strong>`;
          titleRow.appendChild(titleCell);
          table.appendChild(titleRow);

          // Iterate over each item in the array for the key
          for (const itemId of this.favorites[key]) {
            counter++;
            const prop = await this.fetchPropDetails(itemId);
            // Create a new row for each item
            const row = document.createElement("tr");
            row.style.borderBottom = "solid rgba(125,125,125,.1) thin";
            row.style.position="relative"
            row.style.pageBreakInside="avoid"
            
            // Image cell
            const imgCell = document.createElement("td");
            imgCell.style.width='135px';
            const divImg = document.createElement("div");
            divImg.style.backgroundSize = `contain`;
            divImg.style.backgroundRepeat = `no-repeat`;
            divImg.style.backgroundPosition = `center center`;
            divImg.style.backgroundColor = `rgba(125,125,125, .1)`;
            divImg.style.width = "120px";
            divImg.style.height = "120px";

            if(prop.poster_file || (!prop.poster_file && ! prop.set_items.length)) {
              let imageUrl = `${this.mainDomain()}/props/${itemId}/files/${this.$helpers.imageChooser(prop.poster_file, 'large')}`;
              divImg.style.backgroundImage = `url('${imageUrl}')`;
              imgCell.appendChild(divImg);
            }
            let text_hint = `<p>${this.$t('This is a set of other props')}: <ul>`
            if(prop.set_items.length) {
              for(const set_id of prop.set_items) {
                const divImgCopy = divImg.cloneNode(true);
                const set_prop = await this.fetchPropDetails(set_id);
                let set_imageUrl = `${this.mainDomain()}/props/${set_id}/files/${this.$helpers.imageChooser(set_prop.poster_file, 'large')}`;
                divImgCopy.style.backgroundImage = `url('${set_imageUrl}')`;
                imgCell.appendChild(divImgCopy);
                text_hint += `<li>ID ${set_id}: ${set_prop.name[this.$i18n.locale]}`;
                if(set_prop.custom.custom_4.value[this.$i18n.locale]) {
                  text_hint += " - " +set_prop.custom.custom_4.value[this.$i18n.locale]
                }
                text_hint += "</li>";
              }
              text_hint += `</ul></p>`;
            }

            row.appendChild(imgCell);
            
            // Name cell
            const nameCell = document.createElement("td");
            const nameTitle = document.createElement("h4");
            nameTitle.textContent = counter + ". " + prop.name[this.$i18n.locale];
            nameTitle.style.marginTop="0"
            nameCell.appendChild(nameTitle);
            nameCell.innerHTML += `<p>${this.$t('List')}: '${key}' - ${this.$t('Prop ID')}: ${prop.code}</p>`;
            if(prop.custom.custom_4.value[this.$i18n.locale]) {
              nameCell.innerHTML += `<p>${prop.custom.custom_4.value[this.$i18n.locale]}</p>`;
            }
            if(prop.set_items.length) {
              nameCell.innerHTML += text_hint;
            }
            row.appendChild(nameCell);

            // Add things
            const checkmarks = document.createElement("div");
            checkmarks.style.right="0"
            checkmarks.style.top="0"
            checkmarks.style.textAlign="right"
            checkmarks.style.position="absolute"
            checkmarks.innerHTML = `${this.$t('Collected')}: 🔲<br>${this.$t('Returned')}: 🔲`
            nameCell.appendChild(checkmarks);

            // Append the row to the table
            table.appendChild(row);
          }
          if(!this.favorites[key].length) {
            // add a note about no props
            const spacerRow = document.createElement("tr");
            const spacerCell = document.createElement("td");
            spacerCell.colSpan = 2;
            spacerCell.style.fontStyle = "italics";
            spacerCell.style.fontSize = "0.75em";
            spacerCell.innerHTML = this.$t('No props in this list');
            spacerRow.appendChild(spacerCell);
            table.appendChild(spacerRow);
          }
          // add an empty row
          const spacerRow = document.createElement("tr");
          const spacerCell = document.createElement("td");
          spacerCell.colSpan = 2;
          spacerCell.innerHTML = `&nbsp;`;
          spacerRow.appendChild(spacerCell);
          table.appendChild(spacerRow);
        }
      let logo = `<img src="${this.mainDomain()}/logo.svg" style="height:.85em; vertical-align: middle; margin-right: 0.5em;" alt="logo">`;
      let reminder = this.$t('This list is not to be sent to catourne and is only here for your convenience! ❤️');
      this.$helpers.printElement(this.$t('Ça Tourne Favorites'), logo+counter+' ' + this.$t('Favorites'), table, reminder, reminder);
      }
    },

    mainDomain() {
      return new URL(window.location.origin).origin;
    },

    async fetchPropDetails(propId) {
      try {
        const response = await axios.get(`props/${propId}/data.json`);
        return response.data.equipment_data;
      } catch (error) {
        return {};
      }
    },

    downloadFavorites(data) {
      // Convert the data object to a JSON string
      const jsonString = JSON.stringify(data, null, 2);

      // Create a blob with the JSON data and specify the MIME type
      const blob = new Blob([jsonString], { type: 'application/json' });

      // Format the current date as YYMMDD
      const date = new Date();
      const yy = String(date.getFullYear()).slice(-2);
      const mm = String(date.getMonth() + 1).padStart(2, '0');
      const dd = String(date.getDate()).padStart(2, '0');
      const filename = `${yy}${mm}${dd}_export.dbcatourne`;

      // Create a temporary anchor element and trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.style.display = 'none';

      // Append the anchor to the document, click it to trigger the download, then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
</style>