<template>
  <div
    style="display: inline-block; vertical-align:top"
    :style="[
      { width: listView ? '100%' : `${cardSize}px` },
      !listView && $vuetify.breakpoint.smOnly ? { width: '25%' } : {}
    ]"
  >
    <div v-if="prop && prop.name">
      <v-card
        class="pb-2"
        :flat="listView"
        :class="[listView ? 'ma-0 mb-2' : 'mb-2 mr-2', cardClasses]"
        :style="listView ? `min-height: ${cardSize}px;` : ''"
      >
      <!-- 
        :style="listView ? `height: ${cardSize.contains('px') ? cardSize : '120px' };` : ''" -->
        <router-link
          class="pointer"
          tag="div"
          :style="listView ? `clear:both` : ''"
          :to="categories.length ? `/db/${categories.join('/')}/${propIndex}.html` : `/db/${prop.idPath.join('/')}/${propIndex}.html`"
        >
          <span @click="$emit('clicked', true)">
            <!-- on-click should change categories value *and* URL-params/path when !categories but prop.idPath  -->
            <!-- :tag="categories.length ? 'a' : 'span'" -->
            <v-img
              v-if="prop.poster_file"
              :width="listView ? `${cardSize}px` : ''"
              :style="listView ? `float:left; margin-right:1em` : ''"
              aspect-ratio="1"
              :contain="!listView"
              :cover="listView"
              class="grey rounded"
              :class="$vuetify.theme.dark ? 'darken-3' : 'lighten-3'"
              :lazy-src="`props/${propIndex}/files/${$helpers.imageChooser(prop.poster_file, 'small')}`"
              :src="`props/${propIndex}/files/${$helpers.imageChooser(prop.poster_file, 'medium')}`"
            >
              <span v-if="user.role === 'supervisor' || user.role === 'admin'" class="white--text text-shadow--grey50 absolute left small pa-1">
                {{$t('ID')}} {{ propIndex }}
              </span>
              <span v-if="prop.type === 'set'" class="orange--text text-shadow--grey50 small right absolute pa-1">
                {{ $t('Set') }}
              </span>
            </v-img>

            <div
              v-else-if="prop.type === 'set'"
              class="grey text--center rounded"
              :class="$vuetify.theme.dark ? 'darken-3' : 'lighten-3'"
              style="box-sizing: border-box"
              :style="listView ? `width:${cardSize}px; height: ${cardSize}px; float:left; margin-right:1em` : $vuetify.breakpoint.smOnly ? 'height:23vw;' : `height: ${cardSize * .92}px;`"
            >
              <span v-if="user.role === 'supervisor' || user.role === 'admin'" class="white--text text-shadow--grey50 absolute left small pa-1">
                {{$t('ID')}} {{ propIndex }}
              </span>
              <span class="orange--text text-shadow--grey50 small right absolute pa-1">
                {{ $t('Set') }}
              </span>
              <v-icon x-large color="secondary" class="absolute" style="top: calc(50% - .5em);">
                mdi-shape-plus
              </v-icon>
            </div>

            <v-img
              v-else
              :width="listView ? `${cardSize}px` : ''"
              :style="listView ? `float:left; margin-right:1em` : ''"
              class="rounded"
              aspect-ratio="1"
              :contain="!listView"
              :cover="listView"
              :src="require('@/assets/noImage.png')"
              :lazy-src="require('@/assets/noImage_small.png')"
              alt="No image for this prop :("
            >
              <span v-if="user.role === 'supervisor' || user.role === 'admin'" class="white--text text-shadow--grey50 absolute left small pa-1">
                {{$t('ID')}} {{ propIndex }}
              </span>
              <!-- <span class="orange--text text-shadow--grey50 absolute right small pa-1">
                {{ $t('No image :/') }}
              </span> -->
            </v-img>
            <!-- This makes the thing flow around list view on mobile -->
            <v-card-text :title="`${$t(prop.type)}: ${prop.name[$i18n.locale]}`" class="pa-2 pb-0" :class="[!listView && cardSize <= 120 ? 'pt-0' : '', listView ? 'pr-0' : '']">
              <h3 :class="!listView && cardSize <= 120 ? 'ellipsis small' : ''">
                <span v-if="cardSize > 120 && prop.type && prop.type.length && prop.type != 'item'" class="grey--text capitalize">
                  {{ $t(prop.type) }}:
                </span>
                {{ prop.name[$i18n.locale] }}

                <div v-if="listView" class="inlineBlock" :class="$vuetify.breakpoint.xsOnly ? '' : 'absolute right top mr-2 mt-2'">
                  <v-chip
                    dense
                    small
                    class="pr-0"
                  >
                    ~{{ Math.max((prop.qrcodes_of_serial_numbers ? prop.qrcodes_of_serial_numbers.split(",").length : 0), prop.current_quantity_excl_cases || 0, prop.current_quantity) }}<!--
                    -->{{ $t('pcs') }}
                    <Info
                      small
                      class="ml-1"
                      btnClasses="primary"
                      :hover="false"
                      iconClasses="white--text"
                      :title="$t('About quantities')"
                      :text="$t('These numbers are an estimate and do not in any way indicate that this is available for you.<br><br>Please check for yourself in our warehouse!')"
                    ></Info>
                  </v-chip>
                </div>
              </h3>
            </v-card-text>
            <!-- v-if="(cardSize.contains('px') && parseIn(cardSize) > 150) || listView" -->
            <v-card-subtitle v-if="cardSize > 150 || listView" class="pt-1 px-2 pb-0">
              <!-- {{$t('Prop ID')}} {{ propIndex }} -->
              <!-- <br>
              {{ prop.idPath ? prop.idPath.join('/') : 'no idPath' }} -->
              <!-- If search OR FAVS? are displayed, show categories -->
              <p v-if="$vuetify.breakpoint.mdAndUp && prop.categories_human_readable" class="italics">
                <!--   ellipsis(string, length, dots='...', fromEnd=false) -->
                {{ $helpers.ellipsis(prop.categories_human_readable.map(item => item[$i18n.locale]).join(` &rsaquo; `), listView ? 666 : 50, '…', true) }}
              </p>
              <p v-if="$vuetify.breakpoint.smAndUp && prop.custom && prop.custom.custom_4.value[$i18n.locale]">
                {{ $helpers.ellipsis(prop.custom.custom_4.value[$i18n.locale], 80, '…') }}
              </p>
              <div v-if="showTags">
                <v-chip
                  v-for="(tag, hash) in prop.tags"
                  :key="$helpers.createUid()+tag.name['de']"
                  class="capitalize black--text mr-1"
                  x-small
                  :color="tag.color"
                  :to="categories.length ? `/db/${categories.join('/')}?tags=${hash}` : `/db/${prop.idPath.join('/')}?tags=${hash}`"
                >
                  {{ tag.tag_category['de'] === 'Baujahr' && tag.name[$i18n.locale] === '1' ? $t('Antique') : tag.name[$i18n.locale] }}
                </v-chip>
              </div>
            </v-card-subtitle>
          </span>
        </router-link>
        <!-- v-if="(cardSize.contains('px') && parseIn(cardSize) > 150) || listView" -->
        <v-card-actions v-if="cardSize > 150 || listView" class="pt-0" :class="listView ? 'absolute right bottom mr-0 pa-2' : ''">
          <v-chip
            v-if="!listView"
            dense
            small
            class="pr-0"
          >
            ~{{ Math.max((prop.qrcodes_of_serial_numbers ? prop.qrcodes_of_serial_numbers.split(",").length : 0), prop.current_quantity_excl_cases || 0, prop.current_quantity) }}<!--
            -->{{ $t('pcs') }}
            <Info
              small
              class="ml-1"
              btnClasses="primary"
              :hover="false"
              iconClasses="white--text"
              :title="$t('About quantities')"
              :text="$t('These numbers are an estimate and do not in any way indicate that this is available for you.<br><br>Please check for yourself in our warehouse!')"
            ></Info>
          </v-chip>
          <br v-if="$vuetify.breakpoint.xsOnly">
          <v-spacer></v-spacer>
          <Favorite 
            :propId="propIndex"
            :specificFavoritesKey="specificFavoritesKey ? specificFavoritesKey : ''"
            @updatedFavorites="$emit('updatedFavorites')"
          ></Favorite>
        </v-card-actions>
      </v-card>
    </div>
    <v-card v-else class="mr-2 mb-2" :flat="listView">
      <!-- IS LOADING OR NOT AVAILABLE.. -->
      <div
        :style="`width:${cardSize}px; ${listView ? `clear:both; width:100%; height:${cardSize}px;` : ''}`"
        class="rounded pr-2"
        >
        <div 
          class="grey text--center rounded"
          :style="`height:${cardSize}px; ${listView ? `width:${cardSize}px; float:left; margin-right:.5em;` : ''}`"
          :class="$vuetify.theme.dark ? 'darken-3' : 'lighten-3'"
        >
          <v-progress-circular
            v-if="loading"
            indeterminate
            size="33"
            width="3"
            :style="`margin-top: calc(50% - 16px);`"
            color="primary"
            class="ma-a"
          ></v-progress-circular>
          <div v-else
            class="inlineBlock rounded small mx-2 pa-1 error white--text bold"
            style="margin-top: calc(50% - 22px);"
          >
            <v-icon class="white--text" small>mdi-alert</v-icon>
            {{ $t('This prop does not exist') }}:
            <code>{{ propId }}</code>
          </div>
        </div>
        <v-skeleton-loader
          class="pa-2 bone-spacing"
          :class="!loading ? 'disabled pointerInitial' : ''"
          type="heading, paragraph"
        ></v-skeleton-loader>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import Favorite from "../components/Favorite.vue"
import Info from '@/components/Info'

  export default {
    props: {
      auth: Boolean,
      user: Object,
      settings: Object,
      propId: Number,
      propIndex: Number,
      propData: Object,
      cardClasses: String,
      cardSize: Number,
      categories: Array,
      listView: Boolean,
      showTags: Boolean,
      specificFavoritesKey: String,
    },
    components: {
      Favorite,
      Info,
    },
    data() {
      return {
        prop: {},
        loading: true,
      }
    },
    watch: {
      /* propId: {
        immediate: true,
        handler(newPropId) {
          this.fetchPropDetails(newPropId);
        },
      }, */
    },
    methods: {
      async fetchPropDetails(propId) {
        if(!propId) {  // propId === undefined
          // propId is not set
          // this.fetchLastCategory();
          this.prop = {};  // was: null??
          this.loading = false;
          // this.files = [];
          return;
        }
        try {
          const response = await axios.get(`props/${propId}/data.json`);
          this.prop = response.data.equipment_data;
          // this.files = response.data.files;
          //// console.log(this.prop)
        } catch (error) {
          // console.error('Failed to load prop details:', error);
          this.prop = null;
          // this.files = [];
        } finally {
          this.loading = false;
        }
      },
    },
    computed: {
      /* mainDomain() {
        return new URL(window.location.origin).origin;
      } */
    },
    created() {
      if(this.propId) {
        // Json must be fetched because its sent by search
        this.fetchPropDetails(this.propId);
      } else if(this.propData) {
        // Prop is part of category-listing so, data already available
        this.prop = this.propData;
        this.loading = false;
      }
    }
  };
</script>

<style scoped>
  .hover:hover { opacity: 1 !important;}
</style>