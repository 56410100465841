import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'  // points to file src/firebase.js !
import router from '../router/index'
import md5 from 'md5';
let storage = fb.storage;

Vue.use(Vuex);

let unsubscribeSettings = [];
let unsubscribeLogin = [];

// eslint-disable-next-line no-unused-vars
const store = new Vuex.Store({
  state: {
    userProfile: {},
    settings: {},
  },

  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val;
    },
    setSettings(state, val) {
      state.settings = val;
    },
  },

  actions: {
    // eslint-disable-next-line no-unused-vars
    async signup({ dispatch, commit }, form) {
      try {
        // Fetch passphrases
        const passphraseDocRef = fb["db"].collection('passphrases').doc('passphrases');
        const passphraseDoc = await passphraseDocRef.get();
        const passphrases = passphraseDoc.data()?.phrases || [];

        // Validate passphrase
        const passphraseIndex = passphrases.findIndex(hash => hash === md5(form.passphrase));
        if (passphraseIndex === -1) {
          throw { error: 400, message: "Passphrase does not exist or is invalid." };
        }

        // Create new user
        const { user } = await fb.auth.createUserWithEmailAndPassword(
          form.email.toLowerCase(),
          form.password
        );

        // Default user profile
        const userProfile = {
          name: form.name,
          email: form.email.toLowerCase(),
          consent: {
            privacy: form.privacy,
            terms: form.terms,
          },
          role: 'user',
          uid: user.uid,
          favorites: {},
          kicked: false,
          news: form.news,
          emailVerified: false,
          tags: [],
          notes: [],
          public: false,
          guiLanguage: 'de',
          darkMode: false,
          contribution: 0,
          avatar: {},
          createdOn: new Date(),
          lastLogin: new Date(),
        };

        // Save user profile to Firestore
        await fb["db"].collection("users").doc(user.uid).set(userProfile);

        // Remove passphrase if not index 0 (Keep one key the same for all members of the club)
        if (passphraseIndex !== 0) {
          passphrases.splice(passphraseIndex, 1);
          await passphraseDocRef.update({ phrases: passphrases });
        }

        // Fetch user profile and settings
        await dispatch("fetchUserProfile", user);
        dispatch("setSettings");

        // Send verification email
        dispatch("sendEmailVerification");

        // Redirect to /home
        if (router.currentRoute.path === "/signup") {
          router.push("/home?info=welcome");
        }
      } catch (error) {
        console.error("Error in signup:", error);
        throw error.message;
      }
    },

    async fetchUserProfile({ commit }, user) {
      try {
        // Unsubscribe from previous listener
        if (typeof unsubscribeLogin === "function") unsubscribeLogin();

        // Attach a listener to the user's Firestore document
        unsubscribeLogin = fb["db"]
          .collection("users")
          .doc(user.uid)
          .onSnapshot((userProfile) => {
            if (!userProfile.exists) {
              console.error("User profile document missing.");
              fb.auth.signOut();
              commit("setUserProfile", {});
              router.push("/login?error=missingUserProfile");
              return;
            }

            // Commit the user profile to the store
            commit("setUserProfile", {
              ...userProfile.data(),
              emailVerified: user.emailVerified,
              uid: user.uid,
              email: user.email,
            });
          });
      } catch (error) {
        console.error("Error fetching user profile:", error);
        throw error.message;
      }
    },

    setSettings({ commit }) {
      // Ensure only one listener exists
      if (typeof unsubscribeSettings === "function") unsubscribeSettings();
      unsubscribeSettings = fb["db"]
        .collection("settings")
        .doc("settings")
        .onSnapshot((doc) => {
          const settings = doc.data() || {};
          commit("setSettings", settings);
        });
    },

    sendEmailVerification() {
      try {
        const actionCodeSettings = {
          url: 'https://db.catourne.ch/#/db/?success=emailVerified',
        };
        fb.auth.currentUser.sendEmailVerification(actionCodeSettings);
        console.log("Verification email sent.");
      } catch (error) {
        console.error("Error sending verification email:", error);
      }
    },
    
    /*  USER STUFFS */
    async login({ commit, dispatch }, form) {
      try {
        // Sign the user in
        const { user } = await fb.auth.signInWithEmailAndPassword(
          form.email.toLowerCase(),
          form.password
        );
    
        if (!user?.uid) {
          console.error("User UID is undefined. Possible race condition.");
          throw { error: 500, message: "Internal error: Unable to retrieve user UID." };
        }
    
        console.log("User signed in successfully:", user.uid);
    
        // Fetch global settings
        await dispatch("setSettings");
    
        // Fetch and set user profile
        await dispatch("fetchUserProfile", user);
    
        // Update the user's `lastLogin` and `emailVerified` fields
        await fb["db"].collection("users").doc(user.uid).update({
          lastLogin: new Date(),
          emailVerified: user.emailVerified,
        });

        // store.dispatch('addContribution', 1);
    
        console.log("Login process completed successfully.");
        router.push("/db/?success=loggedIn");
        return true;
      } catch (error) {
        if (error?.code === "permission-denied") {
          console.warn("User appears to be kicked or lacks permissions.");
          console.error(error);
          await fb.auth.signOut();
          commit("setUserProfile", {});
          router.push("/login?error=kicked");
        } else {
          console.error("An error occurred during login.");
          console.error(error);
          // Propagate the error for further handling if necessary
          throw error;
        }
      }
    },

    async logout({ commit }, next='success') {
      if(typeof unsubscribeSettings === 'function') unsubscribeSettings();
      if(typeof unsubscribeLogin === 'function') unsubscribeLogin();
      // store.dispatch('unsubscribeAllBut', '');

      await fb.auth.signOut()
      // clear userProfile and redirect to /login
      commit('setUserProfile', {})
      if(next === 'success') {
        router.push('/login?success=loggedOut')
      } else if(next==='kicked') {
        router.push('/login?error=kicked')
      }
    },

    /* FB DOCUMENT HANDLING */
    // eslint-disable-next-line no-unused-vars
    async copyCollection({commit}, payload) {
      const documents = await fb["db"].collection(payload.source).get();
      documents.forEach(async (doc)=> {
        await fb["db"].collection(payload.target).doc(doc.id).set(doc.data());
      })
    },
    
    // eslint-disable-next-line no-unused-vars
    async updateField({commit}, {collection, document, field, data}) {
      await fb["db"].collection(collection).doc(document).update({
        [field]: data
      }).then(function() {
        return true
      }).catch(function(error) {
        throw error;
      });
    },

    /* FILE & FOLDER HANDLING */
    // eslint-disable-next-line no-unused-vars
    async deleteFile({state}, path) {
      // console.log("delete: ", url);
      let deletRef = storage.ref().child(path);
      // Delete the file
      deletRef.delete().then(() => {
        // File deleted successfully
        console.log("Successfully deleted file @ path ", path);
      }).catch((error) => {
        // Uh-oh, an error occurred!
        console.log("Error while deleting file @ path ", path);
        console.log(error.message);
      });
    },
      
    // eslint-disable-next-line no-unused-vars
    deleteFolder({state}, path) {
      let listRef = storage.ref().child(path);
      listRef.listAll().then((res) => {
        res.items.forEach((itemRef) => {
          // console.log("itemRef.fullPath: ", itemRef.fullPath);
          // All the items under listRef.
          itemRef.delete().then(() => {
            // File deleted successfully
            // console.log("success deleted:", itemRef.fullPath);
          }).catch((error) => {
            // Uh-oh, an error occurred!
            console.log(error);
          });
        })
      }).then(() => {
        // FIXME: Does not async await but yeah
        console.log("Deleted folder ", path)
      }).catch((error) => {
        console.log(error);
        // Uh-oh, an error occurred!
      });
    },
  },
});

export default store